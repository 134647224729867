import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastr: ToastrService) { }

  successAlert(text: string = 'Operation Success') {
    this.toastr.success(text, 'Success');
  }

  failedAlert(text: string = 'Operation Failed') {
    this.toastr.error(text, 'Failed');
  }

  unauthorizedAlert(text: string = 'unauthorized') {
    this.toastr.error(text, 'Failed');
  }

  serverErrorAlert(text: string = 'Server Error') {
    this.toastr.error(text, 'Failed');
  }

}
